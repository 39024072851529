<template>
  <div class="main-content">
    <h3 class="marginBottom20">查看办公租赁-{{ form.applyState === 2 ? '待审核' : form.applyState === 3 ? '审核通过' : form.applyState === 4 ? '审核驳回' : '/' }}</h3>
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption">
      <template slot="titleOne">
        <div class="flex align-items">
          <h3>基础信息</h3>
          <div class="width300"></div>
          <el-button v-if="form.applyState === 2" type="text">待审核</el-button>
          <el-button v-if="form.applyState === 3" style="color: #70B603" type="text">审核通过</el-button>
          <el-button v-if="form.applyState === 4" style="color: #D9001B" type="text">审核驳回</el-button>
        </div>
      </template>
      <template slot="title"><div>{{ form.title }}</div></template>
      <template slot="cityId"><div>{{ cityName }}{{ countyName }}</div></template>
      <template slot="businessId"><div>{{ businessName }}</div></template>
      <template slot="buildId"><div>{{ buildName }}</div></template>
      <template slot="buildNum"><div>{{ form.buildNum }} {{ form.isUnit === 1 ? form.unitNumber : '' }} {{ form.isHouse === 1 ? form.houseNumber : '' }}</div></template>
      <template slot="rentalMode"><div>{{ form.rentalMode === 1 ? '整租' : form.rentalMode === 2 ? '合租' : '/' }}</div></template>
      <template slot="bedroom"><div>{{ form.bedroom + '室' + form.office + '厅' + form.toilet + '卫' }}</div></template>
      <template slot="rentalArea"><div>{{ form.rentalArea }} 平米</div></template>
      <template slot="expectPrice"><div>{{ form.expectPrice }} 元/㎡/天</div></template>
      <template slot="buildYear">
        <div>{{ form.buildYear }} 年</div>
        <el-button type="text" style="color: #ebb563">实际房龄以政府信息为准</el-button>
      </template>
      <template slot="pledge">{{ '押' + form.pledge + '付' + form.pay }}</template>
      <template slot="labelIds"><div>{{ labelName || '/' }}</div></template>
      <template slot="titleTwo"><h3>房源信息</h3></template>
      <template slot="orientation"><div>{{ orientationText }}</div></template>
      <template slot="floorCurrent"><div>{{ form.floorCurrent + '/' + form.floorTotal }}</div></template>
      <!-- <template slot="elevator"><div>{{ form.elevator === 1 ? '无' : form.elevator === 2 ? '有' : '/' }}</div></template> -->
      <template slot="elevator"><div>{{ form.elevator || '/' }}</div></template>
      <template slot="checkType"><div>{{ form.checkType === 1 ? '随时入住' : form.checkType === 2 ? form.checkMonth + '个月后' : '/' }}</div></template>
      <template slot="facilities"><div>{{ facilitiesText }}</div></template>
      <template slot="heatMode"><div>{{ form.heatMode === 1 ? '无' : form.heatMode === 2 ? '自供暖' : form.heatMode === 3 ? '集中供暖' : '/' }}</div></template>
      <template slot="isHardcover"><div>{{ form.isHardcover === 1 ? '是' : form.isHardcover === 2 ? '否' : '/' }}</div></template>
      <template slot="hydropower"><div>{{ form.hydropower }}</div></template>
      <template slot="businessType"><div>{{ form.businessType === 1 ? '写字楼' : form.businessType === 2 ? '创意园区' : form.businessType === 3 ? '共享办公' : '/' }}</div></template>
      <template slot="totalFloor"><div>{{ form.totalFloor || '/' }}</div></template>
      <template slot="floorHeight"><div>{{ form.floorHeight || '/' }}</div></template>
      <template slot="singleLayerArea"><div>{{ form.singleLayerArea || '/' }}</div></template>
      <template slot="netLayerHeight"><div>{{ form.netLayerHeight || '/' }}</div></template>
      <template slot="parkSpace"><div>{{ form.parkSpace || '/' }}</div></template>
      <template slot="airConditionType"><div>{{ form.airConditionType || '/' }}</div></template>
      <template slot="propertyFees"><div>{{ form.propertyFees || '/' }}</div></template>
      <template slot="propertyType"><div>{{ form.propertyType || '/' }}</div></template>
      <template slot="developer"><div>{{ form.developer || '/' }}</div></template>
      <template slot="propertyManagement"><div>{{ form.propertyManagement || '/' }}</div></template>
      <template slot="titleThree"><h3>房源资料</h3></template>
      <template slot="titleFour"><div></div></template>
      <template slot="houseOwner"><div>{{ form.houseOwner }}</div></template>
      <template slot="phone"><div>{{ form.phone || '/'}}</div></template>
      <template slot="idCard"><div>{{ form.idCard }}</div></template>
      <template slot="signType"><div>{{ form.signType === 1 ? '线上签订' : form.signType === 2 ? '手动上传授权委托书' : '/' }}</div></template>
      <template slot="signTypeOne">
        <el-button style="background: #c280ff;color: #ffffff;border-color: #c280ff" @click="viewAgreement">查看协议</el-button>
      </template>
      <template slot="rejectReason">
        <div class="flex align-items" style="color: #D9001B">
          <div style="width: 200px;text-align: right;padding-right: 10px;box-sizing: border-box">驳回原因: </div>
          <div>{{ form.rejectReason }}</div>
        </div>
      </template>

      <template slot="menuForm">
        <el-button style="padding: 9px 40px" @click="handleBack">返 回</el-button>
        <el-button v-if="form.applyState === 2" style="color: #ffffff;background: #f59a23;border-color: #f59a23;padding: 9px 40px" @click="handleExamine">审 核</el-button>
      </template>
    </avue-form>

    <Examine v-if="show" :show="show" :examine="examine" @submit="submitExamine" @close="closeExamine"/>
  </div>
</template>

<script>
import { view } from '@/api/house/tenancyExamine';
import {listBuild, listBusiness, listCity, listCounty, viewContract} from '@/api/house/second'
import { listLabel } from '@/api/house/tenancy'
import { orientation } from './data'
import { facilities } from '../tenancy/components/floor'
import Examine from "./examine";
export default {
  name: "detail",
  components: {
    Examine
  },
  data(){
    return{
      cityName: '',
      countyName: '',
      businessName: '',
      buildName: '',
      labelName: '',
      orientation,
      orientationText: '',
      facilities,
      facilitiesText: '',
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: true,
        column: [
          {
            label: '',
            prop: 'titleOne',
            labelWidth: 100,
            span: 24,
          },
          {
            label: '房源标题',
            prop: 'title',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '城市',
            prop: 'cityId',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '商圈',
            prop: 'businessId',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '楼盘名称',
            prop: 'buildId',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房屋地址',
            prop: 'buildNum',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '出租方式',
            prop: 'rentalMode',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '户型',
            prop: 'bedroom',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '出租面积',
            prop: 'rentalArea',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '期望租金',
            prop: 'expectPrice',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '建筑年代',
            prop: 'buildYear',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '付款方式',
            prop: 'pledge',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '标签',
            prop: 'labelIds',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '',
            prop: 'titleTwo',
            labelWidth: 100,
            span: 24,
          },
          {
            label: '朝向',
            prop: 'orientation',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '楼层',
            prop: 'floorCurrent',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '电梯',
            prop: 'elevator',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '入住',
            prop: 'checkType',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '设施',
            prop: 'facilities',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '供暖方式',
            prop: 'heatMode',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '是否精装',
            prop: 'isHardcover',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '水电',
            prop: 'hydropower',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '业态类型',
            prop: 'businessType',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '总楼层',
            prop: 'totalFloor',
            span: 24,
          },
          {
            label: '层高',
            prop: 'floorHeight',
            span: 24,
          },
          {
            label: '单层面积',
            prop: 'singleLayerArea',
            span: 24,
          },
          {
            label: '净层高',
            prop: 'netLayerHeight',
            span: 24,
          },
          {
            label: '停车位',
            prop: 'parkSpace',
            span: 24,
          },
          {
            label: '空调类型',
            prop: 'airConditionType',
            span: 24,
          },
          {
            label: '物业费',
            prop: 'propertyFees',
            span: 24,
          },
          {
            label: '楼盘类型',
            prop: 'propertyType',
            span: 24,
          },
          {
            label: '开发商',
            prop: 'developer',
            span: 24,
          },
          {
            label: '物业公司',
            prop: 'propertyManagement',
            span: 24,
          },
          {
            label: '',
            prop: 'titleThree',
            labelWidth: 100,
            span: 24,
          },
          {
            label: '房源图片上传',
            prop: 'images',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '房主身份证信息',
            prop: 'titleFour',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房主姓名',
            prop: 'houseOwner',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房主手机号',
            prop: 'phone',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '身份证号',
            prop: 'idCard',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '房主身份证正反面',
            prop: 'idCardFront',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '',
            prop: 'idCardBack',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '房本上传',
            prop: 'premisesPermit',
            type: 'upload',
            span: 24,
            dataType: 'string',
            listType: 'picture-card',
            rules: [{ required: true }]
          },
          {
            label: '签订挂牌委托协议',
            prop: 'signType',
            span: 24,
            rules: [{ required: true}]
          },
          {
            label: '',
            prop: 'signTypeOne',
            span: 24,
            display: false
          },
          {
            label: '',
            prop: 'signTypeImage',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            span: 24,
            display: false
          },
          {
            label: '',
            prop: 'rejectReason',
            labelWidth: 0,
            span: 12,
            display: false
          }
        ]
      },
      show: false,
      examine: {
        id: this.$route.query.id
      }
    }
  },
  async mounted(){
    this.loading = true;
    await this.getView();
  },
  methods: {
    async getView(){
      const { data } = await view({
        id: this.$route.query.id
      });
      // console.log(data)

      const rejectReason = this.findObject(this.formOption.column, 'rejectReason');
      if(data.applyState === 4){
        rejectReason.display = true
      }

      this.form = data;
      let orientationArr = data.orientation.split(',');
      this.orientationText = this.orientation.map(item=>{
        if(orientationArr.includes(item.value)){
          return item.label
        }
      }).filter(item=>item).join(';');

      let facilitiesArr = data.facilities.split(',');
      this.facilitiesText = this.facilities.map(item=>{
        if(facilitiesArr.includes(item.id)){
          return item.label
        }
      }).filter(item=>item).join(';');
      const signTypeOne = this.findObject(this.formOption.column, 'signTypeOne');
      const signTypeImage = this.findObject(this.formOption.column, 'signTypeImage');
      if(data.signType === 1){
        signTypeOne.display = true;
        signTypeImage.display = false;
      }else if(data.signType === 2){
        signTypeOne.display = false;
        signTypeImage.display = true;
      }else{
        signTypeOne.display = false;
        signTypeImage.display = false;
      }
      await this.getListLabel();
      await this.getListCity();
    },
    // 城市列表
    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.cityName = data.map(item => {
        if(item.id === this.form.cityId){
          return item.name;
        }
      }).join('');
      await this.getListCounty(this.form.cityId);
    },
    // 区域列表
    async getListCounty(id){
      const { data } = await listCounty({id: id});
      // console.log(data)
      this.countyName = data.map(item => {
        if(item.id === this.form.countyId){
          return item.name;
        }
      }).join('');
      await this.getListBusiness(this.form.countyId);
    },
    // 商圈列表
    async getListBusiness(id){
      const { data } = await listBusiness({id: id});
      // console.log(data)
      this.businessName = data.map(item => {
        if(item.id === this.form.businessId){
          return item.name;
        }
      }).join('');
      await this.getListBuild();
    },
    // 商圈列表
    async getListBuild(){
      const { data } = await listBuild({
        cityId: this.form.cityId,
        countyId: this.form.countyId,
        businessId: this.form.businessId
      })
      // console.log(data)
      this.buildName = data.map(item => {
        if(item.id === this.form.buildId){
          return item.name;
        }
      }).join('');
      // 数据加载完成
      this.loading = false;
    },
    // 标签列表
    async getListLabel(){
      const { data } = await listLabel()
      // console.log(data)
      // const
      const name = data.map(item => {
        if(this.form.labelIds.includes(item.id)){
          return item.name;
        }
      })
      this.labelName = name.filter(item => item).join(';');
    },
    // 查看协议
    async viewAgreement() {
      const {data} = await viewContract({
        id: this.form.contractId
      })
      // console.log(data)
      window.open(data.pageUrl, '_blank')
    },
    // 审核
    handleExamine(row){
      // console.log('审核',row)
      this.show = true;
    },
    async submitExamine(){
      this.show = false;
      this.loading = true;
      await this.getView();
    },
    closeExamine(){
      this.show = false;
    },
    // 返回
    handleBack(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>