import {get, put, post, Delete} from "@/api/axios";

// /web/house/tenancy/list get
export const list = params => get('/web/house/tenancy/list', params);

// /web/house/tenancy/recommend/update put
export const updateRecommend = params => put('/web/house/tenancy/recommend/update', params);

// /web/house/tenancy/state/update put
export const updateState = params => put('/web/house/tenancy/state/update', params);

// /web/house/tenancy/top/update put
export const updateTop = params => put('/web/house/tenancy/top/update', params);

// /web/house/tenancy/add post
export const add = params => post('/web/house/tenancy/add', params);

// /web/house/tenancy/view/{id} get
export const view = params => get('/web/house/tenancy/view/' + params.id, params);

// /web/house/tenancy/delete/{id} delete
export const deleteById = params => Delete('/web/house/tenancy/delete/' + params.id, params);

// /web/house/tenancy/update put
export const update = params => put('/web/house/tenancy/update', params);

// /web/house/new/agent/update put
export const updateAgent = params => put('/web/house/new/agent/update', params);

// /web/house/tenancy/label/list get
export const listLabel = params => get('/web/house/tenancy/label/list',params)

// /web/house/tenancy/sign/pre/view get
export const signView = params => get('/web/house/tenancy/sign/pre/view', params)

// /web/house/tenancy/sign/pre/view/phone get
export const signPhone = params => get('/web/house/tenancy/sign/pre/view/phone', params)

// /web/house/tenancy/sign/commit get
export const signCommit = params => post('/web/house/tenancy/sign/commit', params)

// 二手房户型设置-新增（参考新房）
export const typeAdd = params => put('/web/house/rent/type/add', params)

// 二手房户型设置-查看（参考新房）
export const typeView = params => get('/web/house/rent/type/view/' + params.id, '')
