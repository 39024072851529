import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/house/second/list', params);

// /web/house/second/city/list get
export const listCity = params => get('/web/house/second/city/list', params);

// /web/house/second/city/county/list/{id} get
export const listCounty = params => get('/web/house/second/city/county/list/' + params.id, params);

// /web/house/second/city/county/business/list/{id} get
export const listBusiness = params => get('/web/house/second/city/county/business/list/' + params.id, params);

// /web/house/second/build/list get
export const listBuild = params => get('/web/house/second/build/list', params);

// /web/house/second/label/list get
export const listLabel = params => get('/web/house/second/label/list', params);

// /web/house/second/add post
export const add = params => post('/web/house/second/add', params);

// /web/house/second/view/{id} get
export const view = params => get('/web/house/second/view/' + params.id, '');

// /web/house/second/update put
export const update = params => put('/web/house/second/update', params);

// /web/house/contract/view/{id} get
export const viewContract = params => get('/web/house/contract/view/' + params.id, '');

// /web/house/second/delete/{id} Delete
export const deleteNew = params => Delete('/web/house/second/delete/' + params.id, '');

// /web/house/second/vr/update put
export const updateVr = params => put('/web/house/second/vr/update', params);

// /web/house/second/agent/list get
export const listAgent = params => get('/web/house/second/agent/list', params);

// /web/house/second/agent/update put
export const updateAgent = params => put('/web/house/second/agent/update', params);

// /web/house/second/recommend/update put
export const updateRecommend = params => put('/web/house/second/recommend/update', params);

// /web/house/second/state/update put
export const updateState = params => put('/web/house/second/state/update', params);

// /web/house/second/top/update put
export const updateTop = params => put('/web/house/second/top/update', params);

// /web/house/second/sign/pre/view get
export const signView = params => get('/web/house/second/sign/pre/view', params)

// /web/house/second/sign/pre/view/phone get
export const signPhone = params => get('/web/house/second/sign/pre/view/phone', params)

// /web/house/second/sign/commit get
export const signCommit = params => post('/web/house/second/sign/commit', params)

// 二手房户型设置-新增（参考新房）
export const typeAdd = params => put('/web/house/second/type/add', params)

// 二手房户型设置-查看（参考新房）
export const typeView = params => get('/web/house/second/type/view/' + params.id, '')