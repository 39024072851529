<template>
  <el-dialog
      title=""
      :visible.sync="show"
      width="30%"
      :before-close="handleClose">
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               @submit="handleSubmit">
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import { updateState } from "@/api/house/tenancyExamine"
export default {
  name: "examine",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    examine: {
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return{
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: '审核',
            prop: 'applyState',
            type: 'radio',
            span: 24,
            dicData: [
              {
                label: '审核通过',
                value: 3
              },
              {
                label: '审核驳回',
                value: 4
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择审核结果',
                trigger: 'change'
              }
            ]
          },
          {
            label: '',
            prop: 'rejectReason',
            span: 24,
            type: 'textarea',
            maxlength: 30,
            showWordLimit: true,
            display: false
          }
        ]
      }
    }
  },
  watch: {
    'form.applyState': {
      handler(val){
        this.formOption.column[1].display = val === 4;
      },
      deep: true
    }
  },
  methods: {
    handleSubmit(form,done){
      updateState({
        id: this.examine.id,
        ...form
      }).then(res => {
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('submit')
        }else{
          this.$message.error(res.msg)
        }
        done();
      }).catch(()=> done())
    },
    handleClose(){
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>