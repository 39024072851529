export const option = {
    title: '办公租赁房源审核列表',
    titleSize: 'h3',
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: true, // 显示索引
    indexLabel: '序号', // 索引文本
    indexWidth: 100, // 索引宽度
    size: 'small',
    selection: false, // 显示多选框
    page: true, // 显示分页
    align: 'center',
    menuAlign: 'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '办公租赁房源号',
            prop: 'code',
            width: 200
        },
        {
            label: '房源标题',
            prop: 'title',
            width: 200,
            formatter: (row, column, cellValue) => {
                return `${row.rentalMode === 1 ? '整租' : '合租'} • ${cellValue}`
            },
        },
        {
            label: '城市',
            prop: 'cityName',
            width: 150
        },
        {
            label: '区县',
            prop: 'countyName',
            width: 150
        },
        {
            label: '商圈',
            prop: 'businessName',
            width: 150
        },
        {
            label: '楼盘名称',
            prop: 'buildName',
            width: 150
        },
        {
            label: '租金',
            prop: 'expectPrice',
            width: 150
        },
        {
            label: '付款方式',
            prop: 'pledge',
            formatter: function(row){
                return '押 ' + row.pledge + ' 付 ' + row.pay
            },
            width: 150
        },
        {
            label: '户型',
            prop: 'bedroom',
            formatter: (row) => {
                return row.bedroom + ' 室 ' + row.office + ' 厅 ' + row.toilet + ' 卫'
            },
            width: 150
        },
        {
            label: '使用面积',
            prop: 'rentalArea',
            width: 150
        },
        {
            label: '标签',
            prop: 'labelName',
            width: 200
        },
        {
            label: '录入角色',
            prop: 'entryRole',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '平台' : cellValue === 2 ? '业主' : cellValue === 3 ? '认证经纪人' : cellValue === 4 ? '八爪鱼抓取' : '/';
            },
            width: 150
        },
        {
            label: '录入人名称',
            prop: 'nickname',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 150
        },
        {
            label: '审核状态',
            prop: 'applyState',
            formatter: function(row, column, cellValue){
                return cellValue === 2 ? '待审核' : cellValue === 3 ? '审核通过' : cellValue === 4 ? '审核驳回' : '/';
            },
            width: 150
        },
        {
            label: '驳回原因',
            prop: 'rejectReason',
            formatter: function(row, column, cellValue){
                return cellValue ? cellValue : '/';
            },
            width: 200
        }
    ]
}
export const orientation = [
    {
        label: '东',
        value: '1'
    },
    {
        label: '南',
        value: '2'
    },
    {
        label: '西',
        value: '3'
    },
    {
        label: '北',
        value: '4'
    }
]
export const floor = [
    {
        label: '顶层',
        value: 1
    },
    {
        label: '高',
        value: 2
    },
    {
        label: '中',
        value: 3
    },
    {
        label: '低',
        value: 4
    },
    {
        label: '底层',
        value: 5
    }
]
export const facilities = [
    {
        label: '空调',
        value: '1'
    },
    {
        label: '洗衣机',
        value: '2'
    },
    {
        label: '冰箱',
        value: '3'
    },
    {
        label: '热水器',
        value: '4'
    },
    {
        label: '智能锁',
        value: '5'
    },
    {
        label: '宽带',
        value: '6'
    },
    {
        label: '电视',
        value: '7'
    },
    {
        label: '油烟机',
        value: '8'
    },
    {
        label: '电磁炉',
        value: '9'
    },
    {
        label: '床上用品',
        value: '10'
    }
]